import './unsolicited-ratesheets-snapshot.scss';

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import useSWR from 'swr';

import {
  buildGetIssuersUnsolicitedRatesheetsUrl,
  getIssuerUnsolicitedRatesheets,
} from '../../../api/unsolicited-ratesheets/issuer/get-issuer-unsolicited-ratesheets';
import { getFormattedNumberOrEmpty, rateFormat } from '../../../format-numbers';

export const UnsolicitedRatesheetsSnapshot = ({
  investorTenantId,
  fromDate,
  toDate,
  sourceEntityId,
  onClose,
  show,
}) => {
  const [unsolicitedRatesheetTrade, setUnsolicitedRatesheetTrade] = useState();

  const { data: unsolicitedRatesheets } = useSWR(
    buildGetIssuersUnsolicitedRatesheetsUrl({
      tenantsIds: [investorTenantId],
      from: fromDate,
      to: toDate,
    }),
    getIssuerUnsolicitedRatesheets,
  );

  useEffect(() => {
    if (!unsolicitedRatesheets || !unsolicitedRatesheets.length) {
      return;
    }

    const nextUnsolicitedRatesheetTrade = unsolicitedRatesheets
      .flatMap(({ trades }) => trades)
      .find(({ entityId }) => entityId === sourceEntityId);

    setUnsolicitedRatesheetTrade(nextUnsolicitedRatesheetTrade);
  }, [unsolicitedRatesheets, sourceEntityId]);

  if (
    !unsolicitedRatesheetTrade ||
    !unsolicitedRatesheetTrade.ratesheetsSnapshot ||
    !unsolicitedRatesheetTrade.ratesheetsSnapshot.length
  ) {
    return null;
  }

  const [firstRatesheetSnapshot] = unsolicitedRatesheetTrade.ratesheetsSnapshot;
  const availableTenors = firstRatesheetSnapshot.ratesheet.rates.map(({ tenor }) => tenor);

  return (
    <div className="static-modal">
      <Modal show={show} className="unsolicited-ratesheets-dialog">
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="unsolicitedRatesheet" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table-striped">
            <thead>
              <tr>
                <th colSpan={2}>
                  <FormattedMessage id="unsolicitedRatesheetBank" />
                </th>
                {availableTenors.map((tenor) => (
                  <th className="text-center" key={tenor}>
                    {tenor}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {unsolicitedRatesheetTrade.ratesheetsSnapshot
                .sort(({ isOwnAdi: leftIsOwnAdi }, { isOwnAdi: rightIsOwnAdi }) => rightIsOwnAdi - leftIsOwnAdi)
                .map(({ isOwnAdi, shortRating, longRating, ratesheet }) => (
                  <tr className={`${isOwnAdi ? 'your-adi-row' : ''}`}>
                    {isOwnAdi ? (
                      <td colSpan={2}>
                        <FormattedMessage id="dashboard.dialog.thisAdi" />
                      </td>
                    ) : (
                      <React.Fragment>
                        <td>
                          <label>
                            {longRating} <FormattedMessage id="unsolicitedRatesheetLong" />
                          </label>
                        </td>
                        <td>
                          <label>
                            {shortRating} <FormattedMessage id="unsolicitedRatesheetShort" />
                          </label>
                        </td>
                      </React.Fragment>
                    )}
                    {ratesheet.rates.map(({ rate, tenor, isBestQuote }) => (
                      <td className="text-center">
                        <span
                          className={classNames({
                            'best-coupon': isBestQuote,
                            'accepted-quote': isOwnAdi && unsolicitedRatesheetTrade.tenor === tenor,
                          })}
                        >
                          {getFormattedNumberOrEmpty({ value: rate, format: rateFormat })}
                        </span>
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} className="btn-solid-primary btn-save">
            <FormattedMessage id="close" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

UnsolicitedRatesheetsSnapshot.propTypes = {
  investorTenantId: PropTypes.number.isRequired,
  toDate: PropTypes.instanceOf(Date),
  fromDate: PropTypes.instanceOf(Date),
  sourceEntityId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
};
